// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import SinglePopularProduct from "../product/SinglePopularProduct";

function HomeSlider({data}) {
    return (
        <section className="block py-12 lg:py-24 bg-olaf-green-300 mt-12 lg:mt-24">
            <div className="olafGrid xl:max-w-7xl">
                <div className={"grid grid-cols-12 mb-8"}>
                    <div className={"col-span-12 md:col-span-8"}>
                        <h2>{data?.sectie5Titel}</h2>
                    </div>
                    <div className={"col-span-12 h-full flex items-end justify-end mt-2 md:mt-0 md:col-span-4"}>
                        <a href={data?.sectie5LinkUrl} className={"font-semibold h-fit flex"}>
                            {data?.sectie5LinkTekst}
                            <img src="/assets/icon/chevron-right.svg" alt="Right icon"
                                 className="ml-2"/>
                        </a>
                    </div>
                </div>
                {data?.products?.length === 0 && <p>Momenteel geen populaire producten gevonden.</p>}
                <Swiper
                    modules={[Pagination]}
                    spaceBetween={30}
                    pagination={{clickable: false}}
                    className="homeSwiper"
                    breakpoints={{
                        480: {
                            slidesPerView: 1.5,
                        },
                        768: {
                            slidesPerView: 2.5,
                        },
                        980: {
                            slidesPerView: 3.5,
                        }
                    }}
                >
                    {data?.products?.map((props, i) => (
                        <SwiperSlide key={i}>
                            <SinglePopularProduct props={props}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

export default HomeSlider;
