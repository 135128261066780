import Link from "next/link";
import AuthServices from "../../services/Auth.services";

function SubNavLink({
  uid,
  index,
  url,
  title,
  style,
  logout,
  locale,
  subcategories,
}) {
  function clickEvent() {
    document.body.classList.remove("navOpen");
    if (logout) {
      AuthServices.logout();
    }
  }
  return (
    <li
      className={`pt-2 ml-4 group border-olaf-green-500 border-t ${
        index != 0 ? "mt-2.5" : "mt-2"
      }`}
    >
      <Link legacyBehavior href={url} locale={locale}>
        <a
          className={`text-olaf-green-700 text-sm ${
            style == "thick" ? "font-bold" : "font-normal"
          }`}
          onClick={() => clickEvent()}
        >
          {title}
        </a>
      </Link>
      {subcategories && subcategories.length > 0 && (
        <ul className={`hidden group-hover:block`}>
          {subcategories.map(
            ({ uid, vitemId, name, logout, subcategories }, i) => (
              <SubNavLink
                key={i}
                index={i}
                uid={uid}
                url={"/producten?category=" + vitemId}
                title={name}
                subcategories={subcategories}
                logout={logout}
                style="thick"
              />
            )
          )}
        </ul>
      )}
    </li>
  );
}

export default SubNavLink;
