import Link from "next/link";
import { useEffect, useState } from "react";
import PriceContainer from "../PriceContainer";

function SinglePopularProduct({
  props,
  link = "/product/" + props.uid,
  title = "",
}) {
  const {
    uid,
    name,
    image,
    vitum_id,
    productImages,
    variantImages,
    vitemId = "",
  } = props;

  const [defaultImage, setImage] = useState(
    "/assets/image/placeholder-logo.jpg"
  );

    useEffect(() => {
        findImage();
        // turn off until vitum images are fixed
        // if (variantImages?.length > 0 && variantImages[0].filePath) {
        //     setImage(`https://${process.env.NEXT_PUBLIC_VITUM_STORAGE_NAME}.blob.core.windows.net/${variantImages[0].filePath}`);
        // } else if (productImages?.length > 0 && productImages[0].filePath) {
        //     setImage(`https://${process.env.NEXT_PUBLIC_VITUM_STORAGE_NAME}.blob.core.windows.net/${productImages[0].filePath}`);
        // } else {
        //     findImage();
        // }
    }, []);

  const findImage = async () => {
    let vitum_correct_id = vitum_id ?? vitemId;

    let imageId = await fetch(
      process.env.NEXT_PUBLIC_CRAFT_BASE_URL +
        "/api/products/images/" +
        vitum_correct_id +
        "?single=1",
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json());

    if (imageId && imageId?.img?.length > 0) {
      setImage(imageId?.img);
    } else {
      setImage(defaultImage);
    }
  };

  return (
    <Link legacyBehavior href={link}>
      <a className="relative flex flex-col h-full">
        <img
          src={defaultImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/assets/image/placeholder-logo.jpg";
          }}
          alt="Header image 1"
          className="h-[30vh] w-full object-cover"
        />
        <div className="w-full bg-olaf-green-500/75 px-2 py-2 flex flex-col items-start flex-grow">
          <h3 className="text-sm leading-4 font-bold text-white mr-2 min-h-[50px]">
            {title ? <span>{title}</span> : <span>{name}</span>}
          </h3>

          <div
            className={
              "grid grid-cols-2 w-full flex items-center justify-between gap-4"
            }
          ></div>
        </div>
      </a>
    </Link>
  );
}

export default SinglePopularProduct;
