import Cookies from "js-cookie";
import CookieButton from "./CookieButton";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";

export default function CookieModal() {
  const router = useRouter();
  const [currentCookie, setCurrentCookie] = useState();

  useEffect(() => {
    setTimeout(() => {
      setCurrentCookie(
        Cookies.get("cookie_consent")
          ? Cookies.get("cookie_consent")
          : "noConsent"
      );
    }, 1000);
  }, []);

  const setCookieConsent = (choice) => {
    switch (choice) {
      case "all":
        Cookies.set("cookie_consent", "all", { expires: 365, path: "/" });
        break;
      case "necessary":
        Cookies.set("cookie_consent", "necessary", { expires: 365, path: "/" });
        break;
    }
    router.reload();
  };

  const content = {
    title: "Wij geven om jouw privacy",
    text: "Om je zo goed mogelijk van dienst te zijn maakt olaf-nijenkamp.nl gebruik van cookies. Naast noodzakelijke functionele cookies gebruiken we ook analytics en marketing cookies.",
    necessaryText: "Accepteer noodzakelijke",
    allText: "Accepteer alle",
  };

  if (currentCookie === "noConsent") {
    return (
      <div className="fixed z-50 bottom-0 left-0 sm:bottom-12 sm:left-8 shadow-2xl bg-olaf-green-500 text-white p-8 w-full max-w-[500px] rounded-t-[10px] lg:rounded-tl-[10px] lg:rounded-br-[10px]">
        <p className="font-semibold">{content.title}</p>
        <p className="mt-4 text-base">{content.text}</p>
        <div className="mt-8 sm:flex justify-between space-y-4 lg:space-y-0 lg:space-x-4">
          <CookieButton
            text={content.necessaryText}
            accept="necessary"
            handleFunction={setCookieConsent}
            customStyles="!w-full sm:!w-fit"
          />
          <CookieButton
            text={content.allText}
            accept="all"
            handleFunction={setCookieConsent}
            customStyles="!w-full sm:!w-fit mt-2 sm:mt-0"
          />
        </div>
      </div>
    );
  }
  return <></>;
}
