function ProjectSingleBlock(props) {
    const {text, imageUrl, url, isActive} = props;
    return (
        <div
            {...props}
            className={`relative h-[200px] w-full sm:h-[400px] flex items-end transition-width duration-300 ease-in-out ${isActive ? 'sm:w-[50%]' : 'sm:w-[25%]'}`}>
            <a href={url}>
                <h3 className={`text-white relative z-30 font-bold lg:text-2xl m-6 transition duration-300 ease-in-out ${isActive ? 'opacity-100' : 'sm:opacity-0'}`}>{text}</h3>
                <div
                    className={`projectBlockOverlay transition duration-300 ease-in-out ${isActive ? 'opacity-100' : 'sm:opacity-0'}`}></div>
                <img src={imageUrl} className={"absolute top-0 left-0 w-full h-full object-cover z-10"}/>
            </a>
        </div>
    );
}

export default ProjectSingleBlock;
