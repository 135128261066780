function CookieButton({ text, accept, customStyles, handleFunction }) {
  var version = "";
  switch (accept) {
    case "all":
      version =
        "bg-white text-olaf-green-500 border-white hover:bg-olaf-green-500 hover:text-white";
      break;
    case "necessary":
      version =
        "bg-transparent text-white border-white hover:bg-white hover:text-olaf-green-500";
      break;
    default:
      version = "text-growgo_grey bg-white gg_bttn_white";
  }

  return (
    <a
      className={`group cursor-pointer w-full h-[40px] flex items-center justify-center rounded-tl-[10px] rounded-br-[10px] text-sm lg:text-base font-bold border-2 transition duration-200 ease-in-out ${version}`}
      onClick={() => handleFunction(accept)}
    >
      {text}
    </a>
  );
}

export default CookieButton;
