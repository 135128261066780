import React, {useState} from "react";
import ProjectSingleBlock from "./projects/ProjectSingleBlock";
import parse from "html-react-parser";

function Projects({data}) {
    const [isActiveIndex, setIsActiveIndex] = useState(0);
    const parse = require("html-react-parser");

    return (
        <section className="block py-12 lg:py-24 bg-olaf-green-300 mt-12 lg:mt-24">
            <div className="olafGrid xl:max-w-7xl">
                <div className={"grid grid-cols-12"}>
                    <div className={"col-span-12 md:col-span-8"}>
                        {data?.sectie3Tekst && parse(data?.sectie3Tekst)}
                    </div>
                    <div className={"col-span-12 h-full flex items-end justify-end mt-4 md:mt-0 md:col-span-4"}>
                        <a href={data?.sectie3LinkUrl} className={"font-semibold h-fit flex"}>
                            {data?.sectie3LinkTekst}
                            <img src="/assets/icon/chevron-right.svg" alt="Right icon"
                                 className="ml-2"/>
                        </a>
                    </div>
                </div>
                <div className={"flex flex-col gap-6 sm:flex-row sm:gap-12 mt-12"}>
                    {data?.projects?.length === 0 && <p>Momenteel geen projecten gevonden.</p>}
                    {data?.projects?.map((project, index) =>
                        <ProjectSingleBlock key={index} text={project.text}
                                            imageUrl={project.imageUrl}
                                            url={project.url}
                                            isActive={isActiveIndex === index}
                                            index={index}
                                            onMouseEnter={() => setIsActiveIndex(index)}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Projects;
