import Link from "next/link";
import { useEffect, useState } from "react";
import ImageServices from "../../services/Image.services";

function SingleCategory({
  uid,
  id,
  vitemId,
  name,
  image,
  offerte,
  image1 = null,
  isHome = false,
  slug = "/#",
}) {
  const offerteParam = offerte ? "&offerte=" + offerte : "";
  const [defaultImage, setImage] = useState(image);

    useEffect(() => {
        fetchImage();
        // turn off until vitum images are fixed
        // if (image1?.filePath) {
        //     setImage(`https://${process.env.NEXT_PUBLIC_PADDAP_STORAGE_NAME}.blob.core.windows.net/pictures${image1.filePath}`)
        // } else {
        //     fetchImage();
        // }
    }, []);

  const fetchImage = async () => {
    if (image1?.filePath) {
      setImage(`https://${process.env.NEXT_PUBLIC_PADDAP_STORAGE_NAME}.blob.core.windows.net/pictures/${image1.filePath}`)
      return;
    }
    
    if (image1?.vitumId !== null || image1?.vitumId !== undefined) {
      let image = await ImageServices.getImage(image1?.vitumId);

      if (image && image[0]) {
        setImage(image[0]);
      }
    }
  };

  if (name === "Alles") {
    return (
      <Link legacyBehavior href={"/producten?" + offerteParam}>
        <a className="relative">
          <img
            src={defaultImage}
            alt="Header image 1"
            className="h-[30vh] w-full object-cover"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/assets/image/placeholder.jpg";
            }}
          />
          <div className="absolute bottom-0 left-0 w-full bg-olaf-green-500/75 px-2 py-2 flex items-start">
            <h3 className="text-sm leading-4 font-bold text-white mr-2 line-clamp-1">
              {name}
            </h3>
          </div>
        </a>
      </Link>
    );
  }

  return (
    <Link
      legacyBehavior
      href={`${
        isHome ? "/producten?category=" + vitemId + offerteParam : slug
      }`}
    >
      <a className={"relative category_" + vitemId}>
        <img
          src={defaultImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/assets/image/placeholder.jpg";
          }}
          alt="Header image 1"
          className="h-[30vh] w-full object-cover"
        />
        <div className="absolute bottom-0 left-0 w-full bg-olaf-green-500/75 px-2 py-2 flex items-start">
          <h3 className="text-sm leading-4 font-bold text-white mr-2 line-clamp-1">
            {name}
          </h3>
        </div>
      </a>
    </Link>
  );
}

export default SingleCategory;
