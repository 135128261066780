import Head from "next/head";
import Navbar from "../components/general/Navbar";
import HiddenMenu from "../components/general/HiddenMenu";
import Footer from "../components/general/Footer";
import Button from "../components/general/Button";
import {useState, useEffect} from "react";
import AuthServices from "../services/Auth.services";
import {useRouter} from "next/router";
import content from "/public/content/home.json";
import {useForm} from "react-hook-form";
import DoubleGridContent from "../components/content/DoubleGridContent";
import CookieModal from "../components/cookies/CookieModal";
import HeaderWithCTA from "../components/general/HeaderWithCTA";
import Categories from "../components/general/Categories";
import Projects from "../components/general/Projects";
import HomeSlider from "../components/home/HomeSlider";
import parse from "html-react-parser";

export default function Home({navigation, loggedIn, jwtToken, pageData}) {
    const {locale, locales, asPath} = useRouter();
    const [userInfo, setUserInfo] = useState();
    const parse = require("html-react-parser");

    useEffect(() => {
        if (!jwtToken) {
            return;
        }
        setUserInfo(AuthServices.getUserData(jwtToken));
    }, []);

    const {
        formState: {errors},
    } = useForm({
        defaultValues: {
            product_search: "",
        },
    });

    const localeContent = content.filter((item) => item.locale == locale)[0];

    return (
        <div className="min-h-screen flex flex-col">
            <Head>
                <title>{pageData.seo?.title}</title>
                <meta name="description" content={pageData.seo?.description}/>
                <meta name="image" content={pageData.seo?.image ?? ''}/>
            </Head>

            <header className={''}>
                <Navbar items={navigation}/>
                <HiddenMenu loggedIn={loggedIn} role={userInfo?.role}/>
                <HeaderWithCTA
                    title={localeContent.title}
                    small={false}
                    customStyles="pb-8 lg:pb-10"
                />
            </header>

            <main className="z-10 bg-white">
                <Categories data={pageData} isHome={true}/>

                <DoubleGridContent>
                    <div className="col">
                        {pageData?.sectie2Tekst && parse(pageData?.sectie2Tekst)}
                        <Button url={pageData?.sectie2Knop1Link} style={"primary"} text={pageData?.sectie2Knop1Tekst}
                                rightIcon={"/assets/icon/chevron-right-white.svg"}
                                className={"!w-[225px] mt-12"}/>
                        <Button url={pageData?.sectie2Knop2Link} style={"secondary"} text={pageData?.sectie2Knop2Tekst}
                                rightIcon={"/assets/icon/chevron-right.svg"}
                                className={"!w-[225px] mt-4"}/>
                    </div>
                    <div className="col">
                        <img src={pageData?.sectie2Foto}/>
                    </div>
                </DoubleGridContent>

                <DoubleGridContent>
                    <div className="col">
                        <img src={pageData?.sectie2Foto2}/>
                    </div>
                    <div className="col">
                        {pageData?.sectie2Tekst2 && parse(pageData?.sectie2Tekst2)}
                    </div>
                </DoubleGridContent>

                <Projects data={pageData}/>

                <DoubleGridContent>
                    <div className="col">
                        {pageData?.sectie4Tekst && parse(pageData?.sectie4Tekst)}
                    </div>
                    <div className="col">
                        <img src={pageData?.sectie4Foto}/>
                    </div>
                </DoubleGridContent>

                <HomeSlider data={pageData}/>
                <CookieModal/>
            </main>

            <Footer className="bg-white"/>
        </div>
    );
}

export async function getServerSideProps(context) {
    var loggedIn = context.req.cookies["auth"] ? true : false;
    const jwtToken = context.req.cookies["auth"];

    let foundResult = true;

    AuthServices.cookieCheck(jwtToken, context);
    await AuthServices.refreshToken(jwtToken, context);

    const pageData = await fetch(
        process.env.NEXT_PUBLIC_CRAFT_BASE_URL + "/api/global-sets/home",
        {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    ).then((response) => {
        if (!response.ok) {
            foundResult = false;
            return [];
        }

        return response.json();
    });

    return {
        props: {
            loggedIn: loggedIn || false,
            jwtToken: jwtToken || null,
            pageData: pageData || null,
        }, // will be passed to the page component as props
    };
}
