import Link from "next/link";
import NavLink from "./NavLink";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import navLoggedOut from "../../public/content/navbar/nav-logged-out.json";
import navAdmin from "../../public/content/navbar/nav-admin.json";
import navUser from "../../public/content/navbar/nav-user.json";
import AuthServices from "../../services/Auth.services";

function HiddenMenu({ loggedIn, role }) {
  const { locale, locales, asPath } = useRouter();
  var otherLang = locales.filter((item) => item !== locale);

  const [auth, setAuth] = useState(AuthServices.loggedIn());
  const [navOpen, setNavOpen] = useState(false);
  const [mainNav, setMainNav] = useState(navLoggedOut);

  useEffect(() => {
    document.body.classList.contains("navOpen")
      ? document.body.classList.remove("navOpen")
      : document.body.classList.add("navOpen");
  }, [navOpen]);

  useEffect(() => {
    switch (auth) {
      case true:
        role === "ADMIN" ? setMainNav(navAdmin) : setMainNav(navUser);
        break;
      default:
        setMainNav(navLoggedOut);
    }
  }, [auth]);

  const nav2 = [
    {
      url: "/klantenportaal",
      title: "Klantenportaal",
    },
    {
      url: "/about",
      title: "About",
    },
    {
      url: "/contact",
      title: "Bezoekadres & Contact",
    },
  ];

  const socials = [
    {
      url: "https://www.facebook.com/OlafNijenkampBV/",
      icon: "/assets/icon/facebook.svg",
      alt: "Facebook icon",
    },
    {
      url: "https://twitter.com/olafnijenkamp",
      icon: "/assets/icon/twitter.svg",
      alt: "Twitter icon",
    },
    {
      url: "https://www.linkedin.com/company/olaf-nijenkamp-groothandel-tuinplanten-bv/",
      icon: "/assets/icon/linkedin.svg",
      alt: "Linkedin icon",
    },
    {
      url: "https://www.youtube.com/channel/UCciDXl8S81EtLFc3vVGS98g/featured",
      icon: "/assets/icon/youtube.svg",
      alt: "Youtube icon",
    },
  ];

  return (
    <nav className="hiddenMenu fixed z-[70] top-0 right-0 w-[calc(100%_-_4%)] h-full pt-16 pb-6 px-6 bg-white lg:hidden">
      <div
        className="absolute top-4 right-[4%] w-6 h-6"
        onClick={() =>
          navOpen === true ? setNavOpen(false) : setNavOpen(true)
        }
      >
        <div className="absolute w-full h-[3px] bg-olaf-green-500 top-1/2 transform -translate-y-1/2 rotate-45 rounded-[1px]"></div>
        <div className="absolute w-full h-[3px] bg-olaf-green-500 top-1/2 transform -translate-y-1/2 -rotate-45 rounded-[1px]"></div>
      </div>
      <div className="h-full flex flex-col overflow-y-auto">
        <ul className="flex flex-col">
          {mainNav.map(({ url, title, logout, subcategories }, i) => (
            <NavLink
              key={i}
              index={i}
              url={url}
              title={title}
              subcategories={subcategories}
              logout={logout}
              style="thick"
            />
          ))}
          {/* {otherLang.map((item) => (
            <NavLink
              key={item}
              url={asPath}
              locale={item}
              title={item}
              style="thick"
            />
          ))} */}
        </ul>
        <ul className="flex flex-col mt-auto pt-16">
          {nav2.map(({ url, title }, i) => (
            <NavLink key={i} index={i} url={url} title={title} style="thin" />
          ))}
        </ul>
        <div>
          <p className="font-bold text-olaf-green-700 mt-4">Volg ons op</p>
          <ul className="mt-2.5 flex space-x-2">
            {socials.map(({ url, icon, alt }, i) => (
              <li
                key={i}
                className="w-6 h-6 bg-olaf-green-700 flex items-center justify-center"
              >
                <Link legacyBehavior href={url}>
                  <a target="_blank" rel="noopener">
                    <img src={icon} alt={alt} />
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default HiddenMenu;
