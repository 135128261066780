import SingleCategory from "../product/SingleCategory";

function Categories({data, isHome = false}) {
    return (
        <section className="block py-12 lg:py-24 bg-olaf-green-300">
            <div className="olafGrid xl:max-w-7xl">
                <h2 className={"mb-6 lg:mb-12"}>{data?.sectie1Titel}</h2>
                <div className={"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-8 categoryContainer"}>
                    {data?.categories?.length === 0 && <p>Momenteel geen categorieën gevonden.</p>}
                    {data?.categories?.map((category, index) =>
                        <SingleCategory key={index} name={category?.name} image={"/assets/image/placeholder.jpg"}
                                        image1={category?.image}
                                        vitemId={category?.vitemId}
                                        className={''}
                                        isHome={isHome}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Categories;
