import PriceDisplay from "./PriceDisplay";

export default function PriceContainer({minPrice, maxPrice, option, style}) {

    if (option === 'Geen') {
        return (<></>)
    }

    return (
        <span className="mt-1 text-[10px] lg:text-xs bg-white rounded-md pb-1 pt-1 pl-2 pr-2">
            {minPrice === maxPrice ? (
                <PriceDisplay price={minPrice} option={option} style={"font-bold"}/>
            ) : (
                <>
                    <PriceDisplay price={minPrice * 100} option={option} style={"font-bold"} /> - <PriceDisplay price={maxPrice * 100} option={option} style={"font-bold"}/>
                </>
            )}
          </span>
    );
}