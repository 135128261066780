import Link from "next/link";
import { useRouter } from "next/router";
import CurrentQuotation from "../CurrentQuotation";
import Button from "./Button";

function HeaderWithCTA({
  back,
  image,
  rightText,
  title,
  small,
  edit,
  customStyles,
}) {
  const router = useRouter();

  return (
    <>
      <div
        className={`relative w-full bg-[url('/assets/image/header-image-1.png')] bg-cover bg-no-repeat flex items-end pt-[65px] pb-8 lg:py-2.5 h-fit lg:h-[480px]
                ${customStyles}`}
      >
        <div className="headerGradient"></div>
        <div className="olafGrid">
          <h1 className="relative text-white max-w-[100%] flex items-center">
            {back !== "window" && back && (
              <Link legacyBehavior href={back}>
                <a>
                  <img
                    src="/assets/icon/chevron-left.svg"
                    width="14"
                    height="14"
                    className="mr-2.5 lg:w-6"
                    alt=""
                  />
                </a>
              </Link>
            )}
            {back == "window" && (
              <div onClick={() => router.back()} className="cursor-pointer">
                <img
                  src="/assets/icon/chevron-left.svg"
                  width="14"
                  height="14"
                  className="mr-2.5 lg:w-6"
                  alt=""
                />
              </div>
            )}
            <div
              className={
                "bg-olaf-green-300 py-5 px-10 rounded-tl-[30px] rounded-br-[30px] max-w-[600px] relative sm:pb-[40px]"
              }
            >
              <div className={"flex flex-col gap-4 md:pr-[30%]"}>
                <h1 className={"text-olaf-green-500 mb-2 text-[23px]"}>
                  {title}
                </h1>
                <div className={"flex items-center"}>
                  <img
                    src="/assets/icon/voor-12.svg"
                    width="54"
                    height="54"
                    className="mr-6"
                    alt=""
                  />
                  <p
                    className={
                      "!mt-0 text-olaf-green-500 font-semibold text-[16px]"
                    }
                  >
                    Vóór donderdag 12:00 uur bestellen is volgende week planten
                  </p>
                </div>
                <div className={"flex items-center"}>
                  <img
                    src="/assets/icon/altijd-aa.svg"
                    width="54"
                    height="54"
                    className="mr-6"
                    alt=""
                  />
                  <p
                    className={
                      "!mt-0 text-olaf-green-500 font-semibold text-[16px]"
                    }
                  >
                    Altijd AA kwaliteit bomen{" "}
                    <br className={"hidden sm:block"} /> en planten.
                  </p>
                </div>
                <div className={"flex items-center"}>
                  <img
                    src="/assets/icon/geleverd-vanaf.svg"
                    width="54"
                    height="54"
                    className="mr-6"
                    alt=""
                  />
                  <p
                    className={
                      "!mt-0 text-olaf-green-500 font-semibold text-[16px]"
                    }
                  >
                    Franco geleverd vanaf <br className={"hidden sm:block"} />{" "}
                    €350,00.
                  </p>
                </div>
              </div>
              <Button
                url={"/inloggen"}
                style={"primary"}
                text={"Direct aan de slag"}
                rightIcon={"/assets/icon/chevron-right-white.svg"}
                className={
                  "!w-[225px] mt-4 ml-auto sm:mt-0 sm:absolute sm:right-[20px] sm:bottom-[20px]"
                }
              />
            </div>
          </h1>
        </div>
        <CurrentQuotation />
      </div>
    </>
  );
}

export default HeaderWithCTA;
